@import '/node_modules/modern-normalize/modern-normalize.css';
@import '../node_modules/simplebar-react/dist/simplebar.min.css';
@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
--orange: #f5821f;
}


body {
  margin: 0;
  font-family: 'Play',sans-serif;
  /* Replace with imported font name */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* global styled */

p { 
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

/* Advantages ScrollBar */

.simplebar-wrapper {
  height: 250px;
}

.simplebar-track {
  background-color: white;
}

.simplebar-track.simplebar-vertical {
  width: 41px;
  border: 2px solid var(--orange);
  border-radius: 25px;
  padding-top: 20px;
}

.simplebar-scrollbar::before {
  margin-left: 2px;
  background-color: transparent;
  border: 2px solid var(--orange);
  background-color: var(--orange);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}

.simplebar-scrollbar.simplebar-visible::before{
  opacity: 1;
}

.simplebar-content-wrapper {
  scroll-behavior: smooth;
  transition: transform 0.3s ease-out;
}

/* BtnTabs */

.custom_btn {
  width: 100%;
  height: 40px;
  color: black;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
  background: #b1b1b380;
  line-height: 42px;
  padding: 0;
  border: none;
}

.custom_btn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 11px;
}

.custom_btn:before,
.custom_btn:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: var(--orange);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.5s ease;
}

.custom_btn:before {
  height: 0%;
  width: 2px;
}

.custom_btn:after {
  width: 0%;
  height: 2px;
}

.custom_btn:hover {
  color: var(--orange);
  background: transparent;
}

.custom_btn:hover:before {
  height: 100%;
}

.custom_btn:hover:after {
  width: 100%;
}

.custom_btn span:before,
.custom_btn span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: var(--orange);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.5s ease;
}

.custom_btn span:before {
  width: 2px;
  height: 0%;
}

.custom_btn span:after {
  height: 2px;
  width: 0%;
}

.custom_btn span:hover:before {
  height: 100%;
}

.custom_btn span:hover:after {
  width: 100%;
}


/* main btn */


.button-container {
  align-items: center;
  display: flex;
  position: relative;
  width: 220px;
  height: 90px;
  overflow: hidden;
  background-color: var(--orange);
  transition: 0.5s;
  border-radius: 8px;
}

.button-container .button {
  width: 101%;
  height: 100%;
  font-size: 20px;
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(29) forwards;
  animation: ani2 0.7s steps(29) forwards;
}

.button-container .button:hover {
  -webkit-animation: ani 0.7s steps(29) forwards;
  animation: ani 0.7s steps(29) forwards;
}

.mas {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 20px;
  overflow: hidden;
}

@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

.button {
  width: 101%;
  height: 100%;
  font-size: 20px;
  mask: url("/src/images/btn_mask.png");
  mask-size: 3000% 100%;
  background-color: #000;
  border: none;
  color: white;
  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(70) forwards;
  animation: ani2 0.7s steps(70) forwards;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  -webkit-animation: ani 0.7s steps(70) forwards;
  animation: ani 0.7s steps(70) forwards;
}


/* sliderBtnMediaRules */
  .rightSliderBtn, 
  .leftSliderBtn {
    top: 85%;
    z-index: 10;
  }

  .rightSliderBtn {
    right: 3px;
  }

  .leftSliderBtn {
    left: 3px;
  }

/* react image gallery  */

.image-gallery-icon:hover {
  color: var(--orange);
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(0)
}

.image-gallery-icon:focus {
  outline: 2px solid var(--orange);
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid var(--orange);
}

.image-gallery-swipe{
  height: auto;
}

.image-gallery-slide.center {
  height: 500px;
}

.image-gallery-content,
.image-gallery-slide,
.image-gallery-image {
  max-height: calc(100vh - 80px);
  height: 500px;
}

.image-gallery-thumbnail-image {
  height: 100px;
}

.image-gallery-icon {
  color: var(--orange);
}

/* media rules  */

@media screen and (min-width: 420px) {
  .rightSliderBtn,
  .leftSliderBtn {
      top: 70%;
    }

  .custom_btn span  {
    font-size: 15px;
    }
  
  .image-gallery-content.fullscreen {
    top: 0;
  }
}

@media screen and (min-width: 868px) {
  .image-gallery-content.fullscreen {
    top: 10%;
  }
}

@media screen and (min-width: 1200px) {
  .rightSliderBtn,
  .leftSliderBtn {
      top: 50%;
    }
  .image-gallery-content.fullscreen {
  top: 30%;
  }
}